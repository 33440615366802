<template>
  <div class="sign-contract">
    <div
      class="d-flex flex-row justify-content-between align-items-center flex-nowrap mb-4"
    >
      <div>
        <h2>{{ signedContract.contract.title }}</h2>
        <span class="small">
          {{ $t('CONTRACTS.LABELS.LAST_UPDATED') }}:
          {{ $formatDate(signedContract.contract.updated, 'date-time') }}
        </span>
      </div>
      <div class="buttons d-flex flex-row flex-nowrap">
        <HMButton
          color="brand"
          class="ms-3"
          @click="signContract"
          :disabled="isProcessing"
          :loading="isProcessing"
        >
          <span class="px-3">
            {{ $t('GENERAL.BUTTON.SIGN') }}
          </span>
        </HMButton>
      </div>
    </div>
    <HMFilePreview
      v-if="signedContract.contract.document"
      :file="signedContract.contract.document"
    />
    <div v-else>
      <div v-html="signedContract.contract.content"></div>
    </div>
  </div>
</template>

<script>
import { errorToMessage } from '@/utils/errors';
import SignatureModal from '@/components/contracts/SignatureModal.vue';

export default {
  name: 'SignContractComponent',
  props: {
    signedContract: {
      type: Object,
    },
    signFn: {
      type: Function,
    },
  },
  computed: {
    studioURL() {
      return this.$studioURL;
    },
  },
  data() {
    return {
      isProcessing: false,
    };
  },
  methods: {
    async signContract() {
      this.$modal.show(
        SignatureModal,
        {
          fullName: this.signedContract.full_name,
          onContractSigned: async formData => {
            const payload = {
              ...formData,
              studioURL: this.studioURL,
              uuid: this.signedContract.unique_id,
            };
            try {
              this.isProcessing = true;
              await useStudioStore().signContract(payload);
              this.$notify({
                group: 'app',
                type: 'success',
                title: this.$t('NOTIFICATIONS.TITLE.SUCCESS'),
                text: this.$t(
                    'CONTRACTS.NOTIFICATIONS.CONTRACT_SUCCESSFULLY_SIGNED'
                ),
              });
              this.$emit('onContractSigned');
            } catch (error) {
              this.$notify({
                group: 'app',
                type: 'error',
                title: `Failed to sign contract`,
                text: errorToMessage(
                    error?.response,
                    `Request failed: ${error}`
                ),
                duration: -1,
              });
            } finally {
              this.isProcessing = false;
            }
          },
        },
      );
    },
  },
};
</script>
