<template>
  <div class="sign-contract-modal">
    <HMModalHeader
      :title="$t('CONTRACTS.TITLES.SIGN_CONTRACT')"
      @close="$attrs._close()"
    />
    <Form ref="form" v-slot="{ meta }" @submit="signContract">
      <HMTextInput
        type="text"
        rules="required"
        :label="$t('CONTRACTS.LABELS.FULL_NAME')"
        v-model="form.fullName"
        name="name"
      />
      <div
        class="mt-4 d-flex flex-row flex-nowrap justify-content-between align-items-center"
      >
        <span>{{ $t('CONTRACTS.LABELS.SIGNATURE') }}</span>
        <HMButton @click="clearSignature">
          <span class="c-brand-color">{{ $t('GENERAL.BUTTON.CLEAR') }}</span>
        </HMButton>
      </div>
      <div class="signature-container mt-2">
        <VueSignaturePad width="100%" height="200px" ref="signaturePad" />
      </div>
      <div class="mt-4 c-charcoal">
        {{ $t('CONTRACTS.TEXT.I_CERTIFY_THAT_I_HAVE_READ') }}
      </div>
      <HMModalFooter
        class="mt-3"
        :submitButton="$t('CONTRACTS.BUTTONS.SIGN_CONTRACT')"
        :disabled="!meta.valid"
        :formError="formError"
        @close="$attrs._close()"
      />
    </Form>
  </div>
</template>

<script lang="ts">
import _ from 'lodash-es';
import { Form } from 'vee-validate';
import { VueSignaturePad } from 'vue-signature-pad';

export default {
  name: 'SignatureModal',
  components: {
    Form,
    VueSignaturePad,
  },
  props: {
    fullName: {
      type: String,
    },
    onContractSigned: null
  },
  data() {
    return {
      form: {
        fullName: this.fullName,
        signature: null,
      },
      formError: null,
    };
  },
  computed: {},
  methods: {
    clearSignature() {
      this.$refs.signaturePad.clearSignature();
    },
    async signContract() {
      const signature = this.$refs.signaturePad.saveSignature();
      if (signature.isEmpty) {
        this.$notify({
          group: 'app',
          type: 'error',
          title: this.$t('CONTRACTS.TEXT.PLEASE_PROVIDE_YOUR_SIGNATURE'),
        });
        return;
      }
      this.form.signature = signature.data;
      this.$attrs._close()
      this.onContractSigned(this.form)
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.upload-tile {
  border: dashed;
  display: flex;
  border-radius: 0.5rem;
  border-width: 2px;
  border-color: $medium-gray;
  background-color: $light-gray;
}
.photo-preview {
  display: flex;
}
.upload-module {
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.color-button {
  display: inline-flex;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  border: 1px solid white;
  margin-bottom: 25px;
  cursor: pointer;
  + .color-button {
    margin-left: 10px;
  }
  &--active {
    border: 1px solid $medium-gray;
  }
}

.selection-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 2rem;
  border: 1px solid $light-gray;
  max-width: 50%;
  text-align: center;
  cursor: pointer;
  border-radius: $border-radius;
  &:hover {
    background-color: darken(white, 3%);
  }
}

.signature-container {
  border: 1px dashed $dark-gray;
  border-radius: $border-radius;
}
</style>
